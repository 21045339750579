import React from 'react'
import { useState } from 'react'
import { FormRow, Alert } from './index'

import { useGlobalContext } from '../context/context'

import './cards.css'

const DashboardCard = () => {
  const { user, logoutUser, updateUser, displayAlert, showAlert, isLoading } =
    useGlobalContext()
  const [name, setName] = useState(user?.name)
  const [email, setEmail] = useState(user?.email)
  const [lastName, setLastName] = useState(user?.lastName)

  const balance = user?.balance

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!name || !email || !lastName || !balance) {
      displayAlert()
      return
    }
    updateUser({ name, email, lastName, balance })
  }

  return (
    <section className='dashboard-section'>
      <article className='dashboard-article'>
        <div className='logo-container'>
          {' '}
          <h3 className='logo-h3'>
            Ronin<span className='span-logo'>Worx</span>
          </h3>
        </div>

        <div className='card-title dashboard-card-title'>
          Welcome to the User Dashboard
        </div>
        <>
          <div className='logout-container'>
            <button className='logout-btn' onClick={logoutUser}>
              Logout
            </button>
          </div>
          <div className='dashboard-item'>
            <li>
              User: {user.name} {user.lastName}
            </li>
          </div>
          <div className='dashboard-item'>
            <li>Email: {user.email}</li>
          </div>
          <div className='dashboard-item'>
            <li>Balance: $ {user.balance} USD</li>
          </div>

          <form className='form' onSubmit={handleSubmit}>
            <h3>Change your profile</h3>
            {showAlert && <Alert />}
            <div className='form-center'>
              <FormRow
                type='text'
                name='name'
                value={name}
                handleChange={(e) => setName(e.target.value)}
              />
              <FormRow
                labelText='last name'
                type='text'
                name='lastName'
                value={lastName}
                handleChange={(e) => setLastName(e.target.value)}
              />
              <FormRow
                type='email'
                name='email'
                value={email}
                handleChange={(e) => setEmail(e.target.value)}
              />
              {/* Note to add user address, and other contact info */}
              {/* <FormRow
                type='text'
                name='location'
                value={location}
                handleChange={(e) => setLocation(e.target.value)}
              /> */}
              <button
                className='btn btn-block'
                type='submit'
                disabled={isLoading}
              >
                {isLoading ? 'Please wait..' : 'Save Changes'}
              </button>
            </div>
          </form>
        </>
      </article>
    </section>
  )
}

export default DashboardCard
