import { useState } from 'react'
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai'

const Question = ({ title, info }) => {
  const [showInfo, setShowInfo] = useState(false)
  return (
    <main>
      <article className='question'>
        <header>
          <h4>{title}</h4>
          <button className='faq-btn' onClick={() => setShowInfo(!showInfo)}>
            {showInfo ? <AiOutlineMinus /> : <AiOutlinePlus />}
          </button>
        </header>
        {showInfo && <p>{info}</p>}
      </article>
    </main>
  )
}

export default Question
