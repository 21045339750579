const reviewers = [
  {
    id: 1,
    image:
      'https://images.pexels.com/photos/445109/pexels-photo-445109.jpeg?auto=compress&cs=tinysrgb&w=1600',
    name: 'Freya',
    title: 'Entrepreneur',
    quote: 'Most definitely better than FTX ever dreamed of being.',
  },
  {
    id: 2,
    image:
      'https://images.pexels.com/photos/845457/pexels-photo-845457.jpeg?auto=compress&cs=tinysrgb&w=1600',
    name: 'Chad',
    title: 'hipster',
    quote:
      'I was able to buy an infinite number of skinny jeans with my investment returns',
  },
  {
    id: 3,
    image:
      'https://images.pexels.com/photos/1851164/pexels-photo-1851164.jpeg?auto=compress&cs=tinysrgb&w=1600',
    name: 'Rex',
    title: 'Good Boi',
    quote: 'Filet mignon all day every day with my money',
  },
  {
    id: 4,
    image:
      'https://images.pexels.com/photos/762080/pexels-photo-762080.jpeg?auto=compress&cs=tinysrgb&w=1600',
    name: 'Karen',
    title: 'CEO of Life',
    quote: 'This bank is great, great help for a new small business owner!',
  },
]

export default reviewers
