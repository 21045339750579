import React from 'react'
import { useState, useEffect } from 'react'
import { FiChevronsRight, FiChevronsLeft } from 'react-icons/fi'
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa'
import sliderData from '../components/Customers/sliderData'
import '../components/Customers/slider.css'

const Customers = () => {
  // const [reviewer, setReviewer] = useState(sliderData)
  const [index, setIndex] = useState(0)
  const reviewer = sliderData

  useEffect(() => {
    const lastIndex = reviewer.length - 1
    if (index < 0) {
      setIndex(lastIndex)
    }
    if (index > lastIndex) {
      setIndex(0)
    }
  }, [index, reviewer])

  useEffect(() => {
    let slider = setInterval(() => {
      setIndex(index + 1)
    }, 5000)
    return () => {
      clearInterval(slider)
    }
  }, [index])

  return (
    <section className='slider-section slider-card-section'>
      <div className='slider-title'>
        <h2>
          Ronin<span>Worx</span> Customers
        </h2>
      </div>
      <div className='slider-section-center'>
        {reviewer.map((person, personIndex) => {
          const { id, image, name, title, quote } = person

          let position = 'nextSlide'
          if (personIndex === index) {
            position = 'activeSlide'
          }
          if (
            personIndex === index - 1 ||
            (index === 0 && personIndex === reviewer.length - 1)
          ) {
            position = 'lastSlide'
          }

          return (
            <article className={`${position} slider-article`} key={id}>
              <img src={image} alt={name} className='slider-person-img' />
              <h4>{name}</h4>
              <p className='slider-title'>{title}</p>
              <p className='slider-text'>
                <FaQuoteLeft className='slider-icon' />
                {quote}
                <FaQuoteRight className='slider-icon' />
              </p>
            </article>
          )
        })}
        <button className='slider-prev' onClick={() => setIndex(index - 1)}>
          <FiChevronsLeft />
        </button>
        <button className='slider-next' onClick={() => setIndex(index + 1)}>
          <FiChevronsRight />
        </button>
      </div>
    </section>
  )
}

export default Customers
