import React from 'react'
import { Link } from 'react-router-dom'

const Error = () => {
  return (
    <section className='section dashboard-section'>
      <h4>Oops! Page Doesn't Exist</h4>
      <Link to='/'>
        <button className='btn'>Return Home</button>
      </Link>
    </section>
  )
}

export default Error
