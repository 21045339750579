import React from 'react'
import { useState } from 'react'
import { useGlobalContext } from '../context/context'
import Wrapper from '../components/Wrapper/DepositPage'
import { FormRow, Alert } from '../components'

const Deposit = () => {
  const { user, showAlert, displayAlert, depositBalance, isLoading } =
    useGlobalContext()
  let { name, lastName, email, balance } = user
  const [deposit, setDeposit] = useState(0)

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!balance || !deposit) {
      displayAlert()
      return
    }
    if (deposit < 0) {
      // note to do:  add in dynamic msg for negative deposits
      displayAlert()
      return
    }
    let total = Number(user.balance) + Number(deposit)
    total = parseFloat(total.toFixed(2))
    balance = total

    depositBalance({ name, lastName, email, balance })
    setDeposit(0)
  }

  return (
    <Wrapper>
      <form className='form' onSubmit={handleSubmit}>
        <h3 className='logo'>
          Ronin<span className='span-logo'>Worx</span>
        </h3>
        <h3>Deposit</h3>
        {showAlert && <Alert />}
        <h4>User: {user.name}</h4>
        <h4>${user.balance} Available</h4>
        <div className='form-center'>
          <FormRow
            type='float'
            name='balance'
            value={deposit}
            handleChange={(e) => setDeposit(e.target.value)}
          />
        </div>
        <button type='submit' className='btn btn-block' disabled={isLoading}>
          {isLoading ? 'Please wait..' : 'Deposit'}
        </button>
      </form>
    </Wrapper>
  )
}

export default Deposit
