import styled from 'styled-components'

const Wrapper = styled.section`
  display: grid;
  align-items: center;
  .logo {
    display: block;
    margin: 0 auto;
    margin-bottom: 1.38rem;
  }
  .form {
    max-width: 400px;
    border-top: 5px solid var(--primary-500);
  }

  h3 {
    text-align: center;
  }
  p {
    margin: 0;
    margin-top: 1rem;
    text-align: center;
  }
  .btn {
    margin-top: 1rem;
    cursor: pointer;
    color: var(--white);
    background: var(--clr-primary-5);
    border: transparent;
    border-radius: var(--borderRadius);
    letter-spacing: var(--letterSpacing);
    padding: 0.375rem 0.75rem;
    box-shadow: var(--light-shadow);
    transition: var(--transition);
    text-transform: capitalize;
    display: inline-block;
  }
  .btn:hover {
    background: var(--clr-primary-7);
    box-shadow: var(--dark-shadow);
  }
  .btn-block {
    width: 100%;
  }
  .deposit-btn {
    background: transparent;
    border: transparent;
    color: var(--clr-primary-5);
    cursor: pointer;
    letter-spacing: var(--letterSpacing);
  }
`
export default Wrapper
