const questions = [
  {
    id: 1,
    title: 'Do I have to allow the use of cookies?',
    info: 'Yes JavaScript and Cookies are required.',
  },
  {
    id: 2,
    title: 'How do I change my password?',
    info: 'Please Contact one of our Customer Service Specialists for any security changes to verify your identity.',
  },
  {
    id: 3,
    title: 'What is  my Login ID?',
    info: 'Your login is your email when you originally registered. You can update your login credential in the Account Dashboard. If you cannot remember your email, please contact Customer Service',
  },
  {
    id: 4,
    title: 'What type of investments do you handle?',
    info: 'Coming soon: We will enable our users to invest in traditional Stocks and Bonds, and Cryptocurrencies.',
  },
  {
    id: 5,
    title: 'Can I withdraw my funds at any time?',
    info: 'Yes, you are free to withdraw your fake funds at your leisure. ',
  },
  {
    id: 6,
    title: 'Is this a real bank?',
    info: 'No, this is a Capstone Project for MIT Xpro ',
  },
]
export default questions
