import React from 'react'
import { FaTimes } from 'react-icons/fa'
import { useGlobalContext } from '../context/context'
import { Link } from 'react-router-dom'
import sublinks from './data'
const Sidebar = () => {
  const { isSidebarOpen, closeSidebar, user, logoutUser } = useGlobalContext()
  return (
    <div
      className={`${
        isSidebarOpen ? 'sidebar-wrapper show' : 'sidebar-wrapper'
      }`}
    >
      <aside className='sidebar'>
        <button className='close-btn' onClick={closeSidebar}>
          <FaTimes />
        </button>
        <div className='sidebar-links'>
          {sublinks.map((item, index) => {
            const { links, page } = item
            return (
              <article key={index}>
                <h4>{page}</h4>
                <div className='sidebar-sublinks'>
                  {links.map((link, index) => {
                    const { url, icon, label } = link
                    return (
                      <Link key={index} to={url} onClick={closeSidebar}>
                        {icon}
                        {label}
                      </Link>
                    )
                  })}
                </div>
              </article>
            )
          })}
          {user ? (
            <Link to='register' onClick={logoutUser}>
              <button className='btn sign-in-btn' onClick={closeSidebar}>
                Logout
              </button>
            </Link>
          ) : (
            <Link to='register' onClick={closeSidebar}>
              <button className='btn sign-in-btn'>Login/Register</button>
            </Link>
          )}
        </div>
      </aside>
    </div>
  )
}

export default Sidebar
