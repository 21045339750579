import React, { useState, useContext, useReducer } from 'react'
import reducer from './reducer'
import axios from 'axios'
import {
  DISPLAY_ALERT,
  CLEAR_ALERT,
  REGISTER_USER_BEGIN,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  LOGIN_USER_BEGIN,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  LOGOUT_USER,
  UPDATE_USER_BEGIN,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  UPDATE_USER_WITHDRAW_BEGIN,
  UPDATE_USER_WITHDRAW_SUCCESS,
  UPDATE_USER_WITHDRAW_ERROR,
  UPDATE_USER_DEPOSIT_BEGIN,
  UPDATE_USER_DEPOSIT_SUCCESS,
  UPDATE_USER_DEPOSIT_ERROR,
} from './actions'
import sublinks from '../components/data'

const AppContext = React.createContext()

const jwToken = localStorage.getItem('token')
const user = localStorage.getItem('user')
const balance = localStorage.getItem('balance')

const initialState = {
  isLoading: false,
  showAlert: false,
  alertText: '',
  alertType: '',
  user: user ? JSON.parse(user) : null,
  jwToken: jwToken,
  userBalance: balance || 0,
  userLocation: '',
}

const AppProvider = ({ children }) => {
  // Reducer Globals
  const [state, dispatch] = useReducer(reducer, initialState)

  // Axios Sandbox
  const authFetch = axios.create({
    baseURL: '/api/v1',
  })
  //  axios interceptors

  // request
  authFetch.interceptors.request.use(
    (config) => {
      config.headers['Authorization'] = `Bearer ${state.jwToken}`
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  // response
  authFetch.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      console.log(error.response)
      if (error.response.status === 401) {
        logoutUser()
      }
      return Promise.reject(error)
    }
  )

  // Global State for page functions
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false)
  const [page, setPage] = useState({ page: '', links: [] })
  const [location, setLocation] = useState({})

  const displayAlert = () => {
    dispatch({ type: DISPLAY_ALERT })
    clearAlert()
  }
  const clearAlert = () => {
    setTimeout(() => {
      dispatch({ type: CLEAR_ALERT })
    }, 3000)
  }
  // Local storage functions
  const addUserToLocalStorage = ({ user, jwToken, balance }) => {
    localStorage.setItem('user', JSON.stringify(user))
    localStorage.setItem('token', jwToken)
    localStorage.setItem('balance', balance)
  }

  const removeUserFromLocalStorage = () => {
    localStorage.removeItem('user')
    localStorage.removeItem('token')
    localStorage.removeItem('balance')
  }
  // Register User
  const registerUser = async (currentUser) => {
    dispatch({ type: REGISTER_USER_BEGIN })
    try {
      const response = await axios.post('/api/v1/auth/register', currentUser)
      // console.log(response)
      const { user, jwToken, balance } = response.data
      dispatch({
        type: REGISTER_USER_SUCCESS,
        payload: { user, jwToken, balance },
      })
      // Local Storage for jwToken, user, balance
      addUserToLocalStorage({ user, jwToken, balance })
    } catch (error) {
      // console.log(error.response)
      dispatch({
        type: REGISTER_USER_ERROR,
        payload: { msg: error.response.data.msg },
      })
    }
    clearAlert()
  }
  // Login user
  const loginUser = async (currentUser) => {
    dispatch({ type: LOGIN_USER_BEGIN })
    try {
      const { data } = await axios.post('/api/v1/auth/login', currentUser)
      const { user, jwToken, balance } = data

      dispatch({
        type: LOGIN_USER_SUCCESS,
        payload: { user, jwToken, balance },
      })
      addUserToLocalStorage({ user, jwToken, balance })
    } catch (error) {
      dispatch({
        type: LOGIN_USER_ERROR,
        payload: { msg: error.response.data.msg },
      })
    }
    clearAlert()
  }
  // Log Out User
  const logoutUser = () => {
    dispatch({ type: LOGOUT_USER })
    removeUserFromLocalStorage()
  }
  // UPDATE USER
  const updateUser = async (currentUser) => {
    dispatch({ type: UPDATE_USER_BEGIN })
    try {
      const { data } = await authFetch.patch('/auth/updateUser', currentUser)
      const { user, balance, jwToken } = data

      dispatch({
        type: UPDATE_USER_SUCCESS,
        payload: { user, balance, jwToken },
      })
      addUserToLocalStorage({ user, balance, jwToken })
    } catch (error) {
      if (error.response.status !== 401) {
        dispatch({
          type: UPDATE_USER_ERROR,
          payload: { msg: error.response.data.msg },
        })
      }
    }
    clearAlert()
  }
  // WITHDRAW BALANCE
  const withdrawBalance = async (currentUser) => {
    dispatch({ type: UPDATE_USER_WITHDRAW_BEGIN })
    try {
      const { data } = await authFetch.patch('/auth/updateUser', currentUser)
      const { user, balance, jwToken } = data

      dispatch({
        type: UPDATE_USER_WITHDRAW_SUCCESS,
        payload: { user, balance, jwToken },
      })
      addUserToLocalStorage({ user, balance, jwToken })
    } catch (error) {
      console.log(error.response)
      dispatch({
        type: UPDATE_USER_WITHDRAW_ERROR,
        payload: { msg: error.response.data.msg },
      })
    }
    clearAlert()
  }
  // DEPOSIT BALANCE
  const depositBalance = async (currentUser) => {
    dispatch({ type: UPDATE_USER_DEPOSIT_BEGIN })
    try {
      const { data } = await authFetch.patch('/auth/updateUser', currentUser)
      const { user, balance, jwToken } = data

      dispatch({
        type: UPDATE_USER_DEPOSIT_SUCCESS,
        payload: { user, balance, jwToken },
      })
      addUserToLocalStorage({ user, balance, jwToken })
    } catch (error) {
      console.log(error.response)
      dispatch({
        type: UPDATE_USER_DEPOSIT_ERROR,
        payload: { msg: error.response.data.msg },
      })
    }
    clearAlert()
  }

  // NAV/SIDEBAR/SEUBMENU
  const openSidebar = () => {
    setIsSidebarOpen(true)
  }
  const closeSidebar = () => {
    setIsSidebarOpen(false)
  }
  const openSubmenu = (text, coordinates) => {
    const page = sublinks.find((link) => link.page === text)
    setPage(page)
    setLocation(coordinates)
    setIsSubmenuOpen(true)
  }
  const closeSubmenu = () => {
    setIsSubmenuOpen(false)
  }

  return (
    <AppContext.Provider
      value={{
        ...state,
        isSidebarOpen,
        openSidebar,
        closeSidebar,
        isSubmenuOpen,
        openSubmenu,
        closeSubmenu,
        page,
        location,
        displayAlert,
        registerUser,
        loginUser,
        logoutUser,
        updateUser,
        withdrawBalance,
        depositBalance,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}
// make sure use
const useGlobalContext = () => {
  return useContext(AppContext)
}

export { useGlobalContext, AppProvider, initialState }
