// ALERT
export const DISPLAY_ALERT = 'SHOW_ALERT'
export const CLEAR_ALERT = 'CLEAR_ALERT'
// REGISTER
export const REGISTER_USER_BEGIN = 'REGISTER_USER_BEGIN'
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS'
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR'
// LOGIN
export const LOGIN_USER_BEGIN = 'LOGIN_USER_BEGIN'
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR'
// LOGOUT
export const LOGOUT_USER = 'LOGOUT_USER'
// UPDATE USER STATE
export const UPDATE_USER_BEGIN = 'UPDATE_USER_BEGIN'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR'
// WITHDRAW
export const UPDATE_USER_WITHDRAW_BEGIN = 'UPDATE_USER_WITHDRAW_BEGIN'
export const UPDATE_USER_WITHDRAW_SUCCESS = 'UPDATE_USER_WITHDRAW_SUCCESS'
export const UPDATE_USER_WITHDRAW_ERROR = 'UPDATE_USER_WITHDRAW_ERROR'
// DEPOSIT
export const UPDATE_USER_DEPOSIT_BEGIN = 'UPDATE_USER_DEPOSIT_BEGIN'
export const UPDATE_USER_DEPOSIT_SUCCESS = 'UPDATE_USER_DEPOSIT_SUCCESS'
export const UPDATE_USER_DEPOSIT_ERROR = 'UPDATE_USER_DEPOSIT_ERROR'
