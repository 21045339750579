import {
  DISPLAY_ALERT,
  CLEAR_ALERT,
  REGISTER_USER_BEGIN,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  LOGIN_USER_BEGIN,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  LOGOUT_USER,
  UPDATE_USER_BEGIN,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  UPDATE_USER_WITHDRAW_BEGIN,
  UPDATE_USER_WITHDRAW_SUCCESS,
  UPDATE_USER_WITHDRAW_ERROR,
  UPDATE_USER_DEPOSIT_BEGIN,
  UPDATE_USER_DEPOSIT_SUCCESS,
  UPDATE_USER_DEPOSIT_ERROR,
} from './actions'
import { initialState } from './context'

const reducer = (state, action) => {
  if (action.type === DISPLAY_ALERT) {
    return {
      ...state,
      showAlert: true,
      alertType: 'danger',
      alertText: 'Please provide all values',
    }
  }
  if (action.type === CLEAR_ALERT) {
    return {
      ...state,
      showAlert: false,
      alertType: '',
      alertText: '',
    }
  }
  // REGISTRATION
  if (action.type === REGISTER_USER_BEGIN) {
    return {
      ...state,
      isLoading: true,
    }
  }
  if (action.type === REGISTER_USER_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      jwToken: action.payload.jwToken,
      user: action.payload.user,
      userBalance: action.payload.balance,
      showAlert: true,
      alertType: 'success',
      alertText: 'User Created! Going to Dashboard...',
    }
  }
  if (action.type === REGISTER_USER_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    }
  }
  // LOGIN
  if (action.type === LOGIN_USER_BEGIN) {
    return {
      ...state,
      isLoading: true,
    }
  }
  if (action.type === LOGIN_USER_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      jwToken: action.payload.jwToken,
      user: action.payload.user,
      userBalance: action.payload.balance,
      showAlert: true,
      alertType: 'success',
      alertText: 'Login Successful! Redirecting...',
    }
  }
  if (action.type === LOGIN_USER_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    }
  }
  // LOGOUT
  if (action.type === LOGOUT_USER) {
    return {
      ...initialState,
      user: null,
      jwToken: null,
      userBalance: 0,
    }
  }
  // UPDATE USER PARAMS
  if (action.type === UPDATE_USER_BEGIN) {
    return { ...state, isLoading: true }
  }
  if (action.type === UPDATE_USER_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      jwToken: action.payload.jwToken,
      user: action.payload.user,
      userBalance: action.payload.balance,
      showAlert: true,
      alertType: 'success',
      alertText: 'User Profile Updated',
    }
  }
  if (action.type === UPDATE_USER_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    }
  }
  // WITHDRAW
  if (action.type === UPDATE_USER_WITHDRAW_BEGIN) {
    return {
      ...state,
      isLoading: true,
    }
  }
  if (action.type === UPDATE_USER_WITHDRAW_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      jwToken: action.payload.jwToken,
      user: action.payload.user,
      balance: action.payload.balance,
      showAlert: true,
      alertType: 'success',
      alertText: 'Withdraw Successful',
    }
  }
  if (action.type === UPDATE_USER_WITHDRAW_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: 'Cannot Process Transaction',
    }
  }
  // DEPOSIT
  if (action.type === UPDATE_USER_DEPOSIT_BEGIN) {
    return {
      ...state,
      isLoading: true,
    }
  }
  if (action.type === UPDATE_USER_DEPOSIT_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      jwToken: action.payload.jwToken,
      user: action.payload.user,
      balance: action.payload.balance,
      showAlert: true,
      alertType: 'success',
      alertText: 'Deposit Successful',
    }
  }
  if (action.type === UPDATE_USER_DEPOSIT_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: 'Cannot Process Transaction',
    }
  }
  throw new Error(`no such action: ${action.type}`)
}

export default reducer
