import React from 'react'
import { Link } from 'react-router-dom'
import { useGlobalContext } from '../context/context'
import Customers from './Customers'

const Home = () => {
  const { user, logoutUser } = useGlobalContext()

  return (
    <>
      <article className='hero-info'>
        <h1>
          Welcome to Ronin<span className='span-logo'>Worx</span> Bank
        </h1>
        <p>
          A new way to manage your money through secure banking, sound
          investing, and achieve real financial freedom.{' '}
        </p>
        <div className='home-btn-container'>
          {user ? (
            <button className='create-account-btn' onClick={logoutUser}>
              Logout
            </button>
          ) : (
            <Link to='register'>
              {' '}
              <button className='create-account-btn'>Register/Login</button>
            </Link>
          )}
        </div>
      </article>
      <div className='slider-container'>
        <Customers />
      </div>
    </>
  )
}

export default Home
