import React, { useState } from 'react'
import Wrapper from '../components/Wrapper/WithdrawPage'
import { FormRow, Alert } from '../components'
import { useGlobalContext } from '../context/context'

const Withdraw = () => {
  const { user, showAlert, displayAlert, withdrawBalance, isLoading } =
    useGlobalContext()
  let { name, lastName, email, balance } = user

  const [withdraw, setWithdraw] = useState(0)

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!balance || balance < 0 || withdraw < 0) {
      displayAlert()
      return
    }
    let total = user.balance - withdraw
    total = parseFloat(total.toFixed(2))
    balance = total
    withdrawBalance({ name, lastName, email, balance })
    setWithdraw(0)
  }

  return (
    <Wrapper>
      <form className='form' onSubmit={handleSubmit}>
        <h3 className='logo'>
          Ronin<span className='span-logo'>Worx</span>
        </h3>
        <h3>Withdraw</h3>
        {showAlert && <Alert />}
        <h4>User: {user.name}</h4>
        <h4>${user.balance} Available</h4>
        <div className='form-center'>
          <FormRow
            type='float'
            name='balance'
            value={withdraw}
            handleChange={(e) => setWithdraw(e.target.value)}
          />
        </div>
        <button type='submit' className='btn btn-block' disabled={isLoading}>
          {isLoading ? 'Please wait..' : 'Withdraw'}
        </button>
      </form>
    </Wrapper>
  )
}

export default Withdraw
