import React from 'react'
import { FaRebel, FaGithub, FaTwitter, FaSchool } from 'react-icons/fa'

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='footer-container'>
        <ul className='footer-ul'>
          <li className='footer-li'>
            <a href='https://roninw3.github.io/willh87.github.io/' className='footer-link'>
              <FaRebel className='footer-icon' />
            </a>
          </li>
          <li className='footer-li'>
            <a href='https://www.github.com' className='footer-link'>
              <FaGithub className='footer-icon' />
            </a>
          </li>
          <li className='footer-li'>
            <a href='https://www.twitter.com' className='footer-link'>
              <FaTwitter className='footer-icon' />
            </a>
          </li>
          <li className='footer-li'>
            <a href='https://xpro.mit.edu' className='footer-link'>
              <FaSchool className='footer-icon' />
            </a>
          </li>
        </ul>
      </div>
    </footer>
  )
}

export default Footer
