import { FaCreditCard, FaBook, FaBriefcase, FaHome } from 'react-icons/fa'

import React from 'react'
const sublinks = [
  {
    page: 'online banking',
    links: [
      { label: 'withdraw', icon: <FaCreditCard />, url: 'withdraw' },
      { label: 'deposit', icon: <FaCreditCard />, url: 'deposit' },
      { label: 'investing', icon: <FaBook />, url: 'investing' },
      { label: 'account dashboard', icon: <FaCreditCard />, url: 'dashboard' },
    ],
  },
  {
    page: 'customer support',
    links: [
      { label: 'faq', icon: <FaBook />, url: 'faq' },

      { label: 'contact us', icon: <FaBook />, url: 'contact' },
    ],
  },
  {
    page: 'about us',
    links: [
      { label: 'about', icon: <FaBriefcase />, url: 'about' },
      { label: 'customers', icon: <FaBriefcase />, url: 'customers' },
      { label: 'Home', icon: <FaHome />, url: '/' },
    ],
  },
]

export default sublinks
