import React from 'react'

const Investing = () => {
  return (
    <section className='section dashboard-section'>
      <div className='logo-container'>
        {' '}
        <h3 className='logo-h3'>
          Ronin<span className='span-logo'>Worx</span>
        </h3>
      </div>
      <div className='card-title'>Investing</div>
      <p>Coming Soon</p>
    </section>
  )
}

export default Investing
