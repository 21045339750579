import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { SharedHero } from './pages/'
import {
  About,
  Contact,
  Register,
  Customers,
  Dashboard,
  Deposit,
  Error,
  Faq,
  Home,
  Investing,
  Withdraw,
  ProtectedRoute,
} from './pages/'

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<SharedHero />}>
            <Route index element={<Home />} />

            <Route
              path='withdraw'
              element={
                <ProtectedRoute>
                  <Withdraw />
                </ProtectedRoute>
              }
            />
            <Route
              path='deposit'
              element={
                <ProtectedRoute>
                  <Deposit />
                </ProtectedRoute>
              }
            />
            <Route path='about' element={<About />} />
            <Route path='customers' element={<Customers />} />
            <Route
              path='dashboard'
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route path='register' element={<Register />} />

            <Route path='faq' element={<Faq />} />
            <Route path='contact' element={<Contact />} />
            <Route path='investing' element={<Investing />} />
            <Route path='*' element={<Error />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App
