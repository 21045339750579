import React from 'react'
import faqData from './FAQ/faqData'
import SingleQuestion from './FAQ/Question'

import './FAQ/faq.css'

const FaqCard = () => {
  // const [questions, setQuestions] = useState(faqData)
  const questions = faqData
  return (
    <div className='container'>
      <div className='logo-container'>
        {' '}
        <h3 className='logo-h3'>
          Ronin<span className='span-logo'>Worx</span> FAQ
        </h3>
      </div>
      <section className='info'>
        {questions.map((question) => {
          return (
            <SingleQuestion key={question.id} {...question}></SingleQuestion>
          )
        })}
      </section>
    </div>
  )
}

export default FaqCard
