import React from 'react'

const url = 'https://roninw3.github.io/willh87.github.io/'

const Contact = () => {
  return (
    <section className='section dashboard-section'>
      <h3 className='logo'>
        Ronin<span className='span-logo'>Worx</span>
      </h3>
      <div className='card-title'>Contact</div>
      <p>
        <a href={url} className='portfolio-link'>
          William Hicks
        </a>
        , Developer
      </p>
    </section>
  )
}

export default Contact
