import React from 'react'
import { FaBars, FaUserCircle } from 'react-icons/fa'
import { useGlobalContext } from '../context/context'
import { Link } from 'react-router-dom'

const Navbar = () => {
  const { openSidebar, openSubmenu, closeSubmenu, user } = useGlobalContext()
  const displaySubmenu = (e) => {
    const page = e.target.textContent
    const tempBtn = e.target.getBoundingClientRect()
    const center = (tempBtn.left + tempBtn.right) / 2
    const bottom = tempBtn.bottom - 3
    openSubmenu(page, { center, bottom })
  }
  const handleSubmenu = (e) => {
    if (!e.target.classList.contains('link-btn')) {
      closeSubmenu()
    }
  }
  return (
    <nav className='nav' onMouseOver={handleSubmenu}>
      <div className='nav-center'>
        <div className='nav-header'>
          <Link to='/' className='logo a'>
            <h3>
              Ronin<span className='span-logo'>Worx</span> bank
            </h3>
          </Link>
          <button className='btn toggle-btn' onClick={openSidebar}>
            <FaBars />
          </button>
        </div>
        <ul className='nav-links'>
          <li>
            <button className='link-btn' onMouseOver={displaySubmenu}>
              online banking
            </button>
          </li>
          <li>
            <button className='link-btn' onMouseOver={displaySubmenu}>
              customer support
            </button>
          </li>
          <li>
            <button className='link-btn' onMouseOver={displaySubmenu}>
              about us
            </button>
          </li>
        </ul>
        {user ? (
          <Link to='dashboard'>
            <button
              className='btn'
              style={{
                marginTop: '-0.55rem',
                paddingBottom: '.25rem',
                background: 'transparent',
              }}
            >
              <FaUserCircle
                style={{
                  fontSize: '1.7rem',
                  paddingTop: '0.5rem',
                  marginRight: '0.55rem',
                }}
              />
              {user.name}, ${user.balance}
            </button>
          </Link>
        ) : (
          <Link to='register'>
            <button className='btn signin-btn'>Login/Register</button>
          </Link>
        )}
      </div>
    </nav>
  )
}

export default Navbar
