import React from 'react'

const url = 'https://roninw3.github.io/willh87.github.io/'

const About = () => {
  return (
    <section className='section card-section'>
      <div className='logo-container'>
        {' '}
        <h3 className='logo-h3'>
          Ronin<span className='span-logo'>Worx</span>
        </h3>
      </div>
      <div className='card-title'>About</div>
      <article>
        <h4>What is this?</h4>
        <div className='underline'></div>
        <p>
          In reality this is not an acutal bank, please do not send any money
          here. The transactions are not with real funds. The customers and
          reviews are not real. There is no financial advice given here. This
          deployed app is a capstone project for MIT xPro Full Stack Developer
          program created by{' '}
          <a href={url} className='portfolio-link'>
            William Hicks
          </a>{' '}
          using React, Express, Node and MongoDB with custom styling in CSS, no
          bootstrap.
        </p>
        <h4>Our Mission</h4>
        <p>
          The roadmap for development includes: adding a transaction history
          page, updating the investing page with available TradFi and Crypto
          options, expanding server side routes and endpoints in the API to
          create savings and checking accounts in addition to others, adding a
          mobile check deposit, bill pay section, expanding the user profile to
          include address and other contact info, 2FA, and additional design
          updates.
        </p>
        <h4>Our Promise</h4>
        <p>
          Secure Account Creation and Logins with password hashing, and newly
          issued JWT's with every user update. With no activity in 15 minutes
          your web token will expire and will require a login to view protected
          routes and transact.
        </p>
      </article>
    </section>
  )
}

export default About
