import React from 'react'
import { Navbar, Sidebar, Submenu, Footer } from './index.js'
import { Outlet } from 'react-router-dom'
import { useGlobalContext } from '../context/context'

const SharedHero = () => {
  const { closeSubmenu } = useGlobalContext()
  return (
    <>
      <Navbar />
      <Sidebar />
      <Submenu />
      <section className='hero' onMouseOver={closeSubmenu}>
        <div className='hero-center'></div>
        <Outlet></Outlet>
      </section>
      <Footer />
    </>
  )
}

export default SharedHero
