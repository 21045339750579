import React from 'react'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { FormRow, Alert } from '../components'
import Wrapper from '../components/Wrapper/RegisterPage'
import { useGlobalContext } from '../context/context'

const initialState = {
  name: '',
  email: '',
  password: '',
  isMember: true,
}

const Register = () => {
  // local values
  const navigate = useNavigate()
  const [values, setValues] = useState(initialState)
  // global state and useNavigate
  const { isLoading, showAlert, displayAlert, registerUser, loginUser, user } =
    useGlobalContext()

  useEffect(() => {
    if (user) {
      setTimeout(() => {
        navigate('/dashboard')
      }, 3000)
    }
  }, [user, navigate])

  // toggles member with register or login
  const toggleMember = () => {
    setValues({ ...values, isMember: !values.isMember })
  }

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const { name, password, email, isMember } = values
    if (!email || !password || (!isMember && !name)) {
      displayAlert()
      return
    }
    const currentUser = { name, email, password }
    if (isMember) {
      loginUser(currentUser)
    } else {
      registerUser(currentUser)
    }
  }

  return (
    <Wrapper>
      <form className='form' onSubmit={handleSubmit}>
        <h3 className='logo'>
          Ronin<span className='span-logo'>Worx</span>
        </h3>
        <h3>{values.isMember ? 'Login' : 'Register'}</h3>
        {/* Alert function */}
        {showAlert && <Alert />}
        {/* name input */}
        {!values.isMember && (
          <FormRow
            type='text'
            name='name'
            value={values.name}
            handleChange={handleChange}
          />
        )}

        {/* name input */}
        <FormRow
          type='email'
          name='email'
          value={values.email}
          handleChange={handleChange}
        />
        {/* name input */}
        <FormRow
          type='password'
          name='password'
          value={values.password}
          handleChange={handleChange}
        />
        <button type='submit' className='btn btn-block' disabled={isLoading}>
          submit
        </button>
        <p>
          {values.isMember ? 'Not a member with us?' : 'Already a member?'}
          <button type='button' onClick={toggleMember} className='member-btn'>
            {values.isMember ? 'Register' : 'Login'}
          </button>
        </p>
      </form>
    </Wrapper>
  )
}

export default Register
